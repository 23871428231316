import L from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';

import onLoadAsync from 'shared/on_load_async';
import SolarMarker from '../assets/images/map/solar-marker.svg';
import WindMarker from '../assets/images/map/wind-marker.svg';
import WaterMarker from '../assets/images/map/water-marker.svg';
import BiomassMarker from '../assets/images/map/biomass-marker.svg';
import CitizenSolarMarker from '../assets/images/map/citizen-solar-marker.svg';
import CitizenWindMarker from '../assets/images/map/citizen-wind-marker.svg';
import CitizenWaterMarker from '../assets/images/map/citizen-water-marker.svg';
import CitizenBiomassMarker from '../assets/images/map/citizen-biomass-marker.svg';
import ShadowImage from '../assets/images/map/marker-shadow.png';

function setupMap(mapElement) {
  const mapBoxPublicToken = 'pk.eyJ1IjoiZW5lcmNvb3AiLCJhIjoiY2tlOGx4emtzMG41ZjJ5cm5haGQyOHV2NiJ9.xIRthbOU2uhKEWQXYmKjlg';

  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
  const mymap = L.map(mapElement, {
    gestureHandling: true,
  });

  const cooperativeLatitude = mapElement.dataset.latitude;
  const cooperativeLongitude = mapElement.dataset.longitude;

  const isMobile = window.innerWidth < 800;
  const zoomLevelMobile = parseInt(mapElement.dataset.zoomLevelMobile, 10);
  const zoomLevelDesktop = parseInt(mapElement.dataset.zoomLevelDesktop, 10);
  const zoomLevel = isMobile ? zoomLevelMobile : zoomLevelDesktop;

  mymap.setView([cooperativeLatitude, cooperativeLongitude], zoomLevel);

  L.tileLayer('https://api.mapbox.com/styles/v1/enercoop/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    minZoom: 4,
    maxZoom: 18,
    id: 'ckg6pum420ymw1ajtdzcpbcrb',
    tileSize: 512,
    zoomOffset: -1,
    accessToken: mapBoxPublicToken,
  }).addTo(mymap);

  const defaultIconOptions = {
    iconSize: [42, 42],
    iconAnchor: [17, 40],
    popupAnchor: [0, 0],
    shadowUrl: ShadowImage,
    shadowSize: [42, 42],
    shadowAnchor: [10, 39],
  };

  const icons = {
    solar: L.icon({ iconUrl: SolarMarker, ...defaultIconOptions }),
    wind: L.icon({ iconUrl: WindMarker, ...defaultIconOptions }),
    water: L.icon({ iconUrl: WaterMarker, ...defaultIconOptions }),
    biomass: L.icon({ iconUrl: BiomassMarker, ...defaultIconOptions }),
    citizensolar: L.icon({ iconUrl: CitizenSolarMarker, ...defaultIconOptions }),
    citizenwind: L.icon({ iconUrl: CitizenWindMarker, ...defaultIconOptions }),
    citizenwater: L.icon({ iconUrl: CitizenWaterMarker, ...defaultIconOptions }),
    citizenbiomass: L.icon({ iconUrl: CitizenBiomassMarker, ...defaultIconOptions }),
  };

  const markerGroups = {
    solar: [],
    wind: [],
    water: [],
    biomass: [],
    citizen: [],
  };

  const producers = JSON.parse(mapElement.dataset.producers);
  producers.forEach((producer) => {
    const energyType = producer.energy_type;
    const iconType = producer.is_citizen ? `citizen${energyType}` : energyType;
    const marker = L.marker([producer.latitude, producer.longitude], { icon: icons[iconType] });
    const popupOptions = { className: `${iconType}-energy`, closeButton: false };
    marker.bindPopup(producer.popup_html, popupOptions);

    markerGroups[producer.energy_type].push(marker);
  });

  const layerGroups = {
    solar: L.layerGroup(markerGroups.solar).addTo(mymap),
    wind: L.layerGroup(markerGroups.wind).addTo(mymap),
    water: L.layerGroup(markerGroups.water).addTo(mymap),
    biomass: L.layerGroup(markerGroups.biomass).addTo(mymap),
    citizen: L.layerGroup(markerGroups.citizen).addTo(mymap),
  };

  const overlayMaps = {
    Solaire: layerGroups.solar,
    Eolien: layerGroups.wind,
    Hydraulique: layerGroups.water,
    Biomasse: layerGroups.biomass,
    "<span class='citizen-legend-picto'></span><span>Projet Citoyen</span>": layerGroups.citizen,
  };

  L.control.layers({}, overlayMaps, { collapsed: false }).addTo(mymap).setPosition('topleft');
}

onLoadAsync(() => {
  document.querySelectorAll('.producer-map').forEach(setupMap);
});
